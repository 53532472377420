<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    ref="drawer"
    v-model="drawer"
    :expand-on-hover="expandOnHover"
    mobile-breakpoint="960"
    app
    :mini-variant.sync="mini"
    mini-variant-width="60"
    width="260"
    v-bind="$attrs"
  >
    <!-- <v-list-item class="indigo white--text" style="padding-bottom:2px">
      <v-list-item-content class="py-0">
        <v-list-item-title>
          <span class="font-weight-bold mr-12 white--text text-h2">
            <router-link to="/" class="v-breadcrumbs__item white--text">INFUNI</router-link>
          </span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->
    <v-list
      ref="listItems"
      :class="{ 'pl-2': !mini, 'pr-1': !mini, 'mr-2': !mini, 'pl-3': mini, 'pr-3': mini }"
      expand
      nav
    >
      <div></div>
      <template v-for="(item, i) in menuItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item" :mini="mini" catalog> </base-item-group>
        <base-item v-else :key="`item-${i}`" :item="item" :text="item.text" :mini="mini" catalog />
      </template>
      <div></div>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapState, mapMutations } from "vuex"
export default {
  name: "DashboardCoreDrawer",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    // expandOnHover: false,
    monItems: [],
    mini: null,
    libraries: [],
    items: [
      {
        icon: "mdi-home",
        title: "Home",
        to: "/"
      },
      {
        icon: "mdi-account-supervisor",
        title: "Academics",
        to: "/academics"
      },
      {
        icon: "mdi-alpha-p-box-outline",
        title: "Publons",
        to: "/publons"
      },
      {
        icon: "mdi-alpha-s-box-outline",
        title: "Scopus",
        to: "/scopus"
      },
      {
        icon: "mdi-book-open-page-variant",
        title: "Scopus publications",
        to: "/scopus-publications"
      },
      {
        icon: "mdi-chart-timeline-variant",
        title: "Scientometrics",
        to: "/scientometrics"
      },
      {
        icon: "mdi-poll",
        title: "University KPI",
        to: "/university-kpi"
      },
      {
        icon: "mdi-calendar-range",
        title: "Conferences events",
        to: "/conferences-events"
      },
      {
        icon: "mdi-bookshelf",
        title: "Didactic resources",
        to: "/didactic-resources"
      },
      {
        icon: "mdi-book-open-outline",
        title: "Graduate syllabi",
        to: "/graduate-syllabi"
      }
    ]
  }),
  metaInfo() {
    return {
      title: "Academics"
    }
  },
  computed: {
    ...mapState(["barColor", "barImage", "drawer"]),
    appType() {
      return this.$store.getters.appType
    },
    biblioteca() {
      return this.$store.getters.libraryData.library.public_name
    },
    libraryCode() {
      return this.$store.getters.libraryData.library.code
    },
    accountEmail() {
      return this.$store.getters.userEmail
    },
    menuItems() {
      //this.$log("bar color ", this.barColor)
      const menu = []
      menu.push(...this.items.filter(itm => !itm.ctg))
      //this.$log("menuuu ", menu)
      return menu
    },
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val)
      }
    }
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val)
    }
  },
  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER"
    })
  }
}
</script>
